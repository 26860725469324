import { authenticatedPaths } from '@next-app/src/utils/route-utils'
import { buildAuthenticatedRoute } from '@next-app/src/utils/url-utils'
import { useRouter } from 'next-nprogress-bar'
import { DropdownMenuItemType, DropdownMenuProps } from 'pattern-library/src/basicUI/Dropdowns/DropdownMenu'

// Permissions used for Admin and Accounting
export const LEVEL_WORKSPACE_DD_MENU_OPTIONS_HIGH_PERMISSIONS: DropdownMenuProps['options'] = [
  { label: 'Workspaces', key: 'workspaces', iconName: 'workspaces' },
  { type: 'divider', key: 'divider1' },
  { label: 'Workspace', key: 'publications', iconName: 'fiber_manual_record' },
  { label: 'Workspace Settings', key: 'workspace-settings', iconName: 'settings' },
  { label: 'Workspace Feeds', key: 'workspace-feeds', iconName: 'rss_feed' },
  { type: 'divider', key: 'divider2' },
  { label: 'My Account', key: 'account', iconName: 'account_circle' },
  { type: 'divider', key: 'divider5' },
  { label: 'Log out', key: 'logout', iconName: 'logout' },
]

export const LEVEL_WORKSPACE_DD_MENU_OPTIONS_LOW_PERMISSIONS: DropdownMenuProps['options'] = [
  { label: 'Workspaces', key: 'workspaces', iconName: 'workspaces' },
  { type: 'divider', key: 'divider1' },
  { label: 'Workspace', key: 'publications', iconName: 'fiber_manual_record' },
  { type: 'divider', key: 'divider5' },
  { label: 'My Account', key: 'account', iconName: 'account_circle' },
  { type: 'divider', key: 'divider2' },
  { label: 'Log out', key: 'logout', iconName: 'logout' },
]

export const LEVEL_PUBLICATION_DD_MENU_OPTIONS_HIGH_PERMISSIONS: DropdownMenuProps['options'] = [
  { label: 'Publication', key: 'publication', iconName: 'store' },
  { label: 'Workspace', key: 'publications', iconName: 'fiber_manual_record' },
  { type: 'divider', key: 'divider' },
  { label: 'My Account', key: 'account', iconName: 'account_circle' },
  { label: 'Billing', key: 'billing', iconName: 'payment' },
  { type: 'divider', key: 'divider2' },
  { label: 'Log out', key: 'logout', iconName: 'logout' },
]
export const LEVEL_PUBLICATION_DD_MENU_OPTIONS_LOW_PERMISSIONS: DropdownMenuProps['options'] = [
  { label: 'Publication', key: 'publication', iconName: 'store' },
  { label: 'Workspace', key: 'publications', iconName: 'fiber_manual_record' },
  { type: 'divider', key: 'divider' },
  { label: 'My Account', key: 'account', iconName: 'account_circle' },
  { type: 'divider', key: 'divider2' },
  { label: 'Log out', key: 'logout', iconName: 'logout' },
]

export const NAVIGATION_DD_MENU_CLICK = ({
  workspace,
  brand,
  siteId,
  logout,
}: {
  workspace: string
  brand?: string
  siteId?: string
  logout: () => Promise<void>
}) => {
  const router = useRouter()
  return (item: DropdownMenuItemType) => {
    switch (item.key) {
      case 'logout':
        logout()
        break
      case 'publications':
        router.push(buildAuthenticatedRoute({ workspace }))
        break
      case 'workspace-settings':
        router.push(
          buildAuthenticatedRoute({
            workspace,
            pageSlug: authenticatedPaths.workspaceSettings,
          }),
        )
        break
      case 'account':
        const accountUrl = `${window.location.origin}${authenticatedPaths.account}`
        window.open(accountUrl, '_blank')
        break
      case 'billing':
        router.push(buildAuthenticatedRoute({ workspace, brand, siteId, pageSlug: authenticatedPaths.billing }))
        break
      case 'workspaces':
        router.push(authenticatedPaths.workspaceHome)
        break
      case 'publication':
        router.push(buildAuthenticatedRoute({ workspace, brand, siteId }))
        break
      case 'workspace-feeds':
        router.push(buildAuthenticatedRoute({ workspace, pageSlug: authenticatedPaths.workspaceFeeds }))
        break
      default:
        console.error('Unknown dropdown menu item clicked in AuthenticatedMainTemplate')
    }
  }
}
