import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { styled } from '../../stitches.config'
import { Text } from '../Text'

export const itemStyles = {
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  padding: '$2 $4',
  alignItems: 'center',
  outline: 0,
  '&.active': {
    content: ' ',
    top: '$0',
    left: '$0',
    right: '$0',
    bottom: '$0',
    border: '2px solid $sec',
    backgroundColor: '$gs6',
  },
  [`& ${Text}`]: {
    flex: 1,
    pr: '$2',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}

export const DropdownMenuItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
  '&:before': {
    content: ' ',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: '$none',
    position: 'absolute',
    mx: '$1',
    top: '$0',
    left: '$0',
    right: '$0',
    bottom: '$0',
  },
  '&:after': {
    content: ' ',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: '$none',
    position: 'absolute',
    top: '$0',
    left: '$0',
    right: '$0',
    bottom: '$0',
  },
  variants: {
    type: {
      img: {
        p: '$0',
      },
      text: {
        padding: '$2 $4',
      },
      item: {},
    },
  },
  defaultVariants: {
    type: 'text',
  },
})

export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content, {
  width: '100%',
  overflowY: 'none',
  overflowX: 'auto',
  boxShadow: '$sizes$0 $sizes$3 $sizes$10 -$sizes$3 $colors$b7, $sizes$0 $sizes$3 $sizes$5 -$sizes$4 $colors$b9',
  variants: {
    variant: {
      dark: {
        color: '$gs8',
        [`& ${DropdownMenuItem}`]: {
          '&:not([data-disabled]):hover:after, &:not([data-disabled]):focus:after': {
            backgroundColor: '$b11',
          },
          '&:not([data-disabled]):hover, &:not([data-disabled]):focus': {
            backgroundColor: '$b9',
          },
          '&:not(:last-child):before': {
            borderColor: '$b11',
          },
          '&[data-disabled]': {
            color: '$gs8',
          },
        },
      },
      light: {
        color: '$gs12',
        [`& ${DropdownMenuItem}`]: {
          '&:not([data-disabled]):hover:after, &:not([data-disabled]):focus:after': {
            backgroundColor: '$w11',
          },
          '&:not([data-disabled]):hover, &:not([data-disabled]):focus': {
            backgroundColor: '$w9',
          },
          '&:not(:last-child):before': {
            borderColor: '$w11',
          },
          '&[data-disabled]': {
            color: '$gs8',
            cursor: 'not-allowed',
          },
        },
      },
    },
    softBorder: {
      enable: {
        [`& ${DropdownMenuItem}`]: {
          '&:not(:last-child):before': {
            borderWidth: '0 0 1px 0',
          },
        },
      },
      disable: {
        [`& ${DropdownMenuItem}`]: {
          '&:not(:last-child):before': {
            borderWidth: '0',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'dark',
    softBorder: 'enable',
  },
})

export const DropdownIcon = styled('span', {
  fontSize: '24px',
  pr: '$2',
})
